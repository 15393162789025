<template>
  <div>
    <v-row class="pa-6">
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="(folder, i) in folders"
        :key="i"
        class="py-4"
      >
        <div class="container" @click="enterFiles(folder)">
          <div class="folder_tab"></div>
          <div class="folder d-flex align-center justify-center">
            <div>
                <v-btn
                  dark
                  color="darkblue"
                  small
                  fab
                  @click.stop.prevent="folderedit(folder)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
                <!-- <v-dialog max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      small
                      color="error"
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-dialog> -->
              <div                 class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1rem'
                    : 'font-size: 1rem'
                ">{{ folder.name }}</div>
              <div class="text-caption">
                {{ folder.trainingFiles.length }} {{ $t('courses.courses_arquivo') }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2" class="py-4">
        <div class="container" @click="newFolderDialog = true">
          <div class="folder_tab"></div>
          <div class="folder d-flex align-center justify-center">
            <div>
              <v-icon x-large color="">mdi-plus</v-icon>
              <div class="text-body-1 text--secondary">{{ $t('treinamento.treinamento_adicionar') }}</div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="newFolderDialog" width="800">
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">{{ $t('treinamento.treinamento_novapasta') }}</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="newFolder">
            <v-text-field
              v-model="formFolder.name"
              label="Nome da Pasta"
              color="darkblue"
              :rules="[rules.required]"
            >
            </v-text-field>
            <v-file-input
              v-model="formFolder.files"
              label="Arquivos"
              prepend-icon=""
              placeholder="Insira aqui seus arquivos"
              prepend-inner-icon="mdi-paperclip"
              multiple
              color="darkblue"
              :rules="[rules.file]"
            ></v-file-input>
              <v-autocomplete
                color="blue"
                item-color="darkblue"
                :items="couser"
                v-model="formCurso.fk_courseId"
                :hint="$t('descricao.descricao_curso')"
                persistent-hint
                :label="$t('label.label_curso')"
                item-value="id"
                item-text="name"
                :rules="[rules.option]"
              ></v-autocomplete>  
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="darkpink" text rounded @click="newFolderDialog = false">
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            color="darkpink"
            text
            rounded
            @click="addNewFolder"
            :loading="loading"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editFolderDialog" width="800">
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">{{ $t('treinamento.treinamento_novapasta') }}</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="newFolder">
              <v-text-field
                v-model="editfolders.name"
                label="Nome da Pasta"
                color="darkblue"
                :rules="[rules.required]"
              >
              </v-text-field>
              <v-autocomplete
                color="blue"
                item-color="darkblue"
                :items="couser"
                v-model="editcurso.fk_courseId"
                :hint="$t('descricao.descricao_curso')"
                persistent-hint
                :label="$t('label.label_curso')"
                item-value="id"
                item-text="name"
                :rules="[rules.option]"
              ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="darkpink" text rounded @click="folderCancel()">
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            color="darkpink"
            text
            rounded
            @click="Editfolder()"
            :loading="loading"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data() {
    return {
      folders: [],
      loading: false,
      formFolder: {},
      formCurso: {},
      editfolders: {},
      editcurso: {},
      newFolderDialog: false,
      editFolderDialog: false,
      couser: [],
      folderAssemblies: [],
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        file: (v) => !!v || this.$t('regras.regra_file'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
    };
  },

  created() {
    this.getfolderAssemblies();
    this.getFolders();
    this.getCourses();
  },
  
  methods: {
    enterFiles(item) {
      this.$emit("enterFiles", { path: "files", folderId: item.id });
    },
    async addNewFolder() {
      let validation = this.$refs.newFolder.validate();

      if (validation) {
        this.loading = true;
        let idFolder = await this.registerFolder();

        await this.registerFiles(idFolder);

        this.$refs.newFolder.reset();
        this.newFolderDialog = false;
        this.getFolders();
        this.getfolderAssemblies();
        this.loading = false;
      }
    },

    async registerFolder() {
      try {
        let body = {
          name: this.formFolder.name,
        };
        let res = await axios.post(
          `${url}/trainingFolders`,
          body,
          authorization
        );
        return res.data.success.id;
      } catch (error) {
        console.error(error);
      }
    },

    folderedit(folder){
      for (let x = 0; x < this.folderAssemblies.length; x++) {
        if(this.folderAssemblies[x].fk_folder == folder.id){
          let body = {
            id: folder.id,
            name: folder.name,
          }
          console.log()
          this.editcurso.fk_courseId = this.folderAssemblies[x].fk_courseId
          this.editfolders = body
          this.editFolderDialog = true
        }
      }
      if(this.editFolderDialog == false){
         let body = {
            id: folder.id,
            name: folder.name,
          }
          this.editcurso.fk_courseId = ''
          this.editfolders = body
          this.editFolderDialog = true
      }
    },

    folderCancel(){
      this.editFolderDialog = false
      this.getFolders()
      this.getfolderAssemblies();
    },

    async Editfolder(){
      let folder = await axios.put(
        `${url}/trainingFolders/${this.editfolders.id}`, this.editfolders,
        authorization,
      );
      let course = await axios.put(
        `${url}/folderAssemblies/${this.editfolders.id}`, this.editcurso,
        authorization,
      );
      console.log(folder, "folder")
      console.log(course, "curso")
      this.editFolderDialog = false
      this.getFolders();
      this.getfolderAssemblies();
    },

    async registerFiles(folderId) {
      let bodycurso = {
        fk_courseId: this.formCurso.fk_courseId,
        fk_folder: folderId
      };
      await axios.post(`${url}/folderAssemblies`, bodycurso, authorization);
      for (let index = 0; index < this.formFolder.files.length; index++) {
        const element = this.formFolder.files[index];
        try {
          let formData = new FormData();
          formData.append("file", element, element.name);

          let response = await axios.post(
            `${url}/lessonsExtras/files`,
            formData
          );
          let link = response.data.url;

          let elem = element.name.split(".");
          let name = elem[0];
          let type = "." + elem[1];

          let body = {
            trainingFolderId: folderId,
            name: name,
            type: type,
            size: element.size,
            url: link,
          };
          await axios.post(`${url}/trainingFiles`, body, authorization);
        } catch (error) {
          console.error(error);
        }
      }
    },

    async getFolders() {
      let res = await axios.get(`${url}/trainingFolders`, authorization);
      this.folders = res.data;
    },

    getCourses() {
      axios
        .get(`${url}/courses`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          this.couser = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getfolderAssemblies() {
      let res = await axios.get(`${url}/folderAssemblies`, authorization);
      this.folderAssemblies = res.data;
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  padding: 5px;
  text-align: center;
}
.folder_tab,
.folder {
  margin: 0 auto;
  background-color: #ffe189;
  cursor: pointer;
}
.folder_tab {
  width: 40%;
  height: 15px;
  margin-right: 60%;
  border-radius: 10px 20px 0 0;
}
.folder {
  height: 150px;
  border-radius: 0 10px 10px 10px;
  box-shadow: 3px 6px 5px 0px #cccccc;
}
</style>
